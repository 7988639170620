@import "@/styles/common";
.CallusLayer {
  position: absolute;
  width: 6.88rem;
  height: 5.5rem;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #ffffff;
  border-radius: 0.14rem;
  padding-left: 2.23rem;
  .qr_code-wrapper {
    position: absolute;
    left: -0.5rem;
    top: 0.6rem;
    height: auto;
    .code-item {
      height: 0.6rem;
      width: 0.6rem;
      background: #124089;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0.1rem;
      border-radius: 0.38rem 0 0 0.38rem;
      .icon-qq {
        width: 0.27rem;
        height: 0.31rem;
      }
      .icon {
        width: 0.31rem;
        height: 0.26rem;
      }
      &:hover {
        background: #259ed6;
        .code-wrapper {
          display: flex;
        }
      }
      .code-wrapper {
        position: absolute;
        z-index: 1;
        top: -0.3rem;
        left: 0.5rem;
        width: 1.16rem;
        height: 1.2rem;
        background: #fff;
        border-radius: 0.08rem;
        display: none;
        align-items: center;
        justify-content: center;
        .code {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
  .iconcha {
    font-size: 18px !important;
    color: rgba($color: #666, $alpha: 0.55) !important;
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
  }
  .left-icon {
    position: absolute;
    top: 0rem;
    left: -0.19rem;
    width: 3.53rem;
    height: 5.57rem;
  }
  .success-box {
    //height: 570px;
    & > * {
      display: block;
      margin: 0 auto;
      text-align: center;
    }
    img {
      width: 1.2rem;
      height: 1.2rem;
      margin: 1.35rem auto 0.32rem auto;
    }
    h5 {
      height: 0.32rem;
      font-size: 0.32rem;
      line-height: 0.48rem;
      margin-bottom: 0.2rem;
    }
    p {
      font-size: 0.18rem;
      color: #999999;
      line-height: 0.27rem;
    }
  }
  .login-box {
    .login-list {
      display: flex;
      .login-item {
        height: 30px;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        text-align: center;
        line-height: 15px;
        margin-top: 58px;
        margin-right: 50px;
        margin-bottom: 25px;
      }
      .login-item.active {
        color: #5471fe;
        border-bottom: 4px solid #5471fe;
      }
    }
  }
  #login-form {
    padding: 0.44rem 0.43rem;
    .login-form-title {
      font-size: 0.14rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #124089;
      line-height: 0.21rem;
      letter-spacing: 0.01rem;
    }
    .form-item {
      margin-top: 0.22rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .label {
        width: 0.58rem;
        text-align: justify;
        text-align-last: justify;
        font-size: 0.18rem;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #124089;
        line-height: 0.27rem;
        letter-spacing: 0.01rem;
        margin-right: 0.09rem;
      }
      .put-wrapper {
        width: 2.95rem;
        height: 0.41rem;
        background: #f5f5f5;
        border-radius: 0.08rem;
        .put {
          height: 100%;
          width: 100%;
          line-height: 100%;
          border-radius: 0.08rem;
          border-bottom: 0px;
          padding: 0 0.2rem;
          border: none;
          background-color: transparent;
          font-size: 0.14rem;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #333;
          &::placeholder {
            font-size: 0.14rem;
            font-family: SourceHanSansCN-Regular, SourceHanSansCN;
            font-weight: 400;
            color: #878787;
            line-height: 0.21rem;
            letter-spacing: 0.01rem;
          }
        }
        // border: 0.01rem solid #FF0000;
      }
    }
    .name-box,
    .email-box,
    .number-box,
    .agent-box {
      position: relative;
    }

    .textarea-box {
      margin-top: 0.37rem;
      width: 3.61rem;
      height: 0.98rem;
      textarea {
        position: relative;
        height: 100%;
        width: 100%;
        background: #f5f5f5;
        padding: 0.1rem 0.1rem;
        border: none;
        border-radius: 0.08rem;
        resize: none;
        &::placeholder {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 100%;
          text-align: center;
          transform: translateX(-50%) translateY(-50%);
          font-size: 0.14rem;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #878787;
          line-height: 0.21rem;
          letter-spacing: 0.01rem;
        }
      }
    }
    .submit-wrapper {
      display: flex;
      justify-content: flex-end;
      width: 3.61rem;
      margin-top: 0.36rem;
    }
    .submit {
      width: 1.33rem;
      height: 0.36rem;
      background: linear-gradient(147deg, #2082c1 0%, #124089 100%);
      border-radius: 4px;
      line-height: 0.36rem;
      border: none;
      font-size: 0.21rem;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
    .submit:hover {
      background: linear-gradient(147deg, #2082c1 0%, #124089 100%);
      opacity: 1;
    }
    .no-account {
      font-size: 14px;
      color: $gray9;
      width: 476px;
    }
    .no-account a:last-child {
      color: #5471fe;
      cursor: pointer;
    }
  }
}
.CallusLayer {
  .name-box,
  .number-box,
  .agent-box,
  .email-box {
    position: relative;
    small {
      position: absolute;
      left: 0.2rem;
      top: 0.44rem;
      color: #ff0000;
      @include transition(opacity 0.2s linear);
    }
  }

  #login-form {
    & > label {
      height: 49px;
      margin-bottom: 28px;
      input {
        margin-top: 22px;

        padding-left: 0;
        text-indent: 0;
      }
    }
  }
  .valid-fail {
    * {
      color: red !important;
    }
    input {
      border: 0.01rem solid red !important;
    }
  }
}

// @include media-1366 {
//   .CallusLayer {
//     top: 45% !important;
//     .textarea-box-input{
//       height: 120px!important;
//     }
//     .name-box,
//     .number-box,
//     .agent-box {
//       margin-bottom: 35px !important;

//     }
//   }
// }
