@import "@/styles/common";
.Login {
  height: 455px;
  width: 730px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 14px;
  padding-left: 265px;
  .close-icon {
    width: 27px;
    height: 27px;
    background: url("https://oss.5rs.me/oss/uploadfe/png/9a8163fd5c8170be0f714371e180c2ff.png") top left/100% 100%
      no-repeat;
    position: absolute;
    right: -31px;
    top: 7px;
    cursor: pointer;
  }
  .wx-login {
    background: url("https://oss.5rs.me/oss/uploadfe/png/75519a9e0c0436a5b76fbdb79823db63.png") no-repeat center;
    width: 265px;
    height: 100%;
    position: absolute;
    left: 0;
    background-size: auto 100%;
    border-radius: 14px 0 0 14px;
    &::before {
      position: absolute;
      bottom: 0px;
      left: 0px;
      content: " ";
      width: 216px;
      height: 256px;
      z-index: 0;
      transform: translateZ(0);
      background: url("https://oss.5rs.me/oss/uploadfe/png/d97f661d237ae50a33894468c39e1b8f.png") top left/100% 100%
        no-repeat;
    }
    .wx-login-content {
      padding-top: 36px;
      text-align: center;
      .wx-login-contentTitle {
        font-size: 36px;
        font-family: SweiMarkerLegCJKsc-Bold, SweiMarkerLegCJKsc;
        font-weight: bold;
        color: #ffffff;
        line-height: 43px;
        letter-spacing: 3px;
        margin-bottom: 14px;
      }
      .wx-login-contentItem {
        padding-left: 52px;
        text-align: left;
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
        letter-spacing: 1px;
      }
    }
    .wx-qrCode-wrapper {
      transform: translateZ(1px);
      margin: 78px auto 20px;
      width: 153px;
      height: 153px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
    }
    .wx-qrCode {
      width: 142px;
      height: 142px;
      background: url("https://oss.5rs.me/oss/uploadfe/png/8c885ccc90e14247a20132853e7778ec.png") top left/100% 100%
        no-repeat;
    }
    .login-text {
      text-align: center;
      font-size: 14px;
      transform: translateZ(1px);
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #ffffff;
      line-height: 21px;
      letter-spacing: 1px;
    }
  }
  .login-box {
    background: #ffffff;
    padding: 65px 42px;
    border-radius: 0 14px 14px 0;
    height: 100%;
    .login-list {
      display: flex;
      .login-item {
        margin-right: 42px;
        height: 30px;
        cursor: pointer;
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #000000;
        line-height: 27px;
        &:last-child {
          margin-right: 0px;
        }
      }
      .login-item.active {
        color: #124089;
        font-weight: bold;
        border-bottom: 4px solid #124089;
      }
    }
  }
  #login-form {
    margin-top: 12px;
    .login-form-title {
      font-size: 12px;
      font-family: SourceHanSansCN-Light, SourceHanSansCN;
      font-weight: 300;
      color: #000000;
      line-height: 18px;
      letter-spacing: 1px;
    }
    .put-box {
      display: flex;
      align-items: center;
      margin-top: 35px;
      .label {
        width: 52px;
        text-align: justify;
        text-align-last: justify;
        font-size: 18px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #124089;
        line-height: 27px;
      }
      .put-wrapper {
        position: relative;
        p {
          position: absolute;
          display: none;
          top: 60px;
          left: 37px;
          font-size: 12px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #de1313;
          line-height: 15px;
          zoom: 0.83;
        }
        &.fail {
          .put {
            border: 1px solid #ff0000;
          }
          p {
            display: block;
          }
        }
      }
      .put {
        margin-left: 10px;
        padding: 0 20px;
        width: 295px;
        height: 41px;
        background: #f5f5f5;
        border-radius: 8px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #333;
        line-height: 21px;
        letter-spacing: 1px;
        border: none;
        &::placeholder {
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #878787;
          line-height: 21px;
          letter-spacing: 1px;
        }
      }
    }
    .submit {
      cursor: pointer;
      margin-left: 63px;
      margin-top: 72px;
      width: 265px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #878787;
      border-radius: 8px;
      font-size: 21px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #ffffff;
      line-height: 32px;
      letter-spacing: 1px;
    }
    .no-account {
      margin-top: 15px;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      letter-spacing: 1px;
    }
    .no-account a:last-child {
      color: #124089;
      cursor: pointer;
    }
  }
}
